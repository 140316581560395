import { escapeRegExp } from 'lodash-es';
import {
  startOfToday,
  format,
  getYear,
  getMonth,
  addDays,
  addMonths,
  addYears,
} from 'date-fns';
import VueI18n from 'vue-i18n';

import { DEFAULT_I18N, getMonthList } from '@/plugins/i18n';
import { InvoiceNumberService, ProfileService } from '@/lib/services';
import { DATE_FORMAT_ISO } from '@/lib/constants';
import { Profile } from '@/models';

export interface TranslateData {
  sender?: string;
  invoicenumber?: string;
  quotationnumber?: string;
}

export async function getDefaultTranslateData(
  profile?: Profile,
): Promise<TranslateData> {
  const previewData: TranslateData = {};

  await InvoiceNumberService.get().then((r) => {
    previewData.invoicenumber = r.invoice;
    previewData.quotationnumber = r.quotation;
  });

  if (!profile) {
    profile = await ProfileService.getDefault();
  }
  previewData.sender = profile.lineCompanyName;

  return previewData;
}

export type Mapping = { [original: string]: string };

export function translateTags(text: string, pairs: Mapping): string {
  const regexes = Object.keys(pairs).map(escapeRegExp);
  return text
    .split(RegExp(`(${regexes.join('|')})`))
    .map((token: string) => pairs[token] || token)
    .join('');
}

function appendTags(map: Mapping, tags: string[], value: any) {
  for (const tag of tags) {
    map[`{${tag}}`] = value.toString();
  }
}

export function buildTagMap(
  data: TranslateData = {},
  t: VueI18n | null = null,
): Mapping {
  t = t || DEFAULT_I18N;
  const monthNames = getMonthList(t);

  const map: Mapping = {};
  const today = startOfToday();
  const year = getYear(today);
  const dueDate = addDays(today, 14);
  const monthNameOffset = (offset: number) =>
    monthNames[getMonth(addMonths(today, offset))];

  appendTags(map, ['companyname', 'bedrijfsnaam'], 'Preview Company');
  appendTags(map, ['companycontact', 'contactpersoon'], 'John Smith');
  appendTags(map, ['sender', 'afzender'], data.sender || 'Sender Company');
  appendTags(
    map,
    ['invoicenumber', 'factuurnummer'],
    data.invoicenumber || `${year}TESTINVOICE00001`,
  );
  appendTags(
    map,
    ['invoicedate', 'factuurdatum'],
    format(today, DATE_FORMAT_ISO),
  );
  appendTags(
    map,
    ['invoiceduedate', 'vervaldatum'],
    format(dueDate, DATE_FORMAT_ISO),
  );
  appendTags(map, ['invoiceamount', 'factuurbedrag'], '€ 100.00');

  appendTags(map, ['currentmonth', 'huidigemaand'], monthNameOffset(0));
  appendTags(map, ['previousmonth', 'vorigemaand'], monthNameOffset(-1));
  appendTags(map, ['nextmonth', 'volgendemaand'], monthNameOffset(1));
  appendTags(map, ['currentyear', 'huidigejaar'], getYear(today));
  appendTags(map, ['previousyear', 'vorigejaar'], getYear(addYears(today, -1)));
  appendTags(map, ['nextyear', 'volgendejaar'], getYear(addYears(today, 1)));
  appendTags(
    map,
    ['paymentlink', 'betaallink'],
    `<a href="#">${t.tc('email.tags.previews.payment-link')}</a>`,
  );
  appendTags(
    map,
    ['viewinvoice', 'bekijkfactuur'],
    `<a href="#">${t.tc('email.tags.previews.view-invoice')}</a>`,
  );

  appendTags(
    map,
    ['quotationnumber', 'offertenummer'],
    data.quotationnumber || `${year}TESTQUOTATION00001`,
  );
  appendTags(
    map,
    ['acceptbutton', 'accepteerknop'],
    `<a href="#">${t.tc('email.tags.previews.accept-button')}.</a>`,
  );
  return map;
}

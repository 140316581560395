















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { unwrapError } from '@/lib/helpers';
import {
  ExternalInvoiceService,
  PaymentSettingsService,
  ProfileService,
  IntegrationService,
} from '@/lib/services';
import { ExternalInvoiceInfo } from '@/lib/services/externalinvoice';
import ViewInvoiceModal from '@/components/ViewInvoiceModal.vue';
import {
  buildTagMap,
  getDefaultTranslateData,
  translateTags,
} from '@/lib/tagtranslator';
import { Invoice } from '@/models';

@Component({
  components: { ViewInvoiceModal },
})
export default class PayPage extends Vue {
  loading: boolean = true;
  error: string = '';
  shortId: string = '';

  data: ExternalInvoiceInfo;
  logo: string = '';

  showViewer: boolean = false;
  loadedViewer: boolean = false;

  $refs: {
    'invoice-viewer': any;
  };

  async mounted(): Promise<void> {
    this.shortId = this.$route.params['id'];
    if (!this.shortId) {
      this.error = 'Invalid short code';
      return;
    }

    if (this.isPreview) {
      this.data = await this.previewData();
      this.loading = false;
      return;
    }

    try {
      this.data = await ExternalInvoiceService.get(this.shortId);
      this.logo = await ExternalInvoiceService.logo(this.shortId);
      this.loading = false;
    } catch (e) {
      this.error = unwrapError(e);
    }
  }

  get isPreview(): boolean {
    return this.shortId === 'preview';
  }

  async viewInvoice(): Promise<void> {
    if (this.isPreview) {
      return;
    }
    this.showViewer = true;

    if (!this.loadedViewer) {
      const blob = await ExternalInvoiceService.view(this.shortId);
      this.$refs['invoice-viewer'].setBlob(blob);
      this.loadedViewer = true;
    }
  }

  async startPayment(method: string): Promise<void> {
    if (this.isPreview) {
      return;
    }

    this.loading = true;
    try {
      window.location.href = await ExternalInvoiceService.pay(
        this.shortId,
        method,
      );
    } catch (e) {
      this.error = unwrapError(e);
    }
  }

  get shouldShowPaymentOptions(): boolean {
    if (!this.data.invoice.id) return true; // Only true in preview mode
    return !this.data.paid && this.data.invoice.calculated.totalInclVat > 0;
  }

  async previewData(): Promise<ExternalInvoiceInfo> {
    const profile = await ProfileService.getDefault();
    if (profile.logo) {
      // Not await here, I want to do this while the other things are happily at work
      ProfileService.getLogo(profile.logo).then((logo) => (this.logo = logo));
    }

    const tags = buildTagMap(await getDefaultTranslateData(profile));
    const paymentSettings = await PaymentSettingsService.get();
    const message = translateTags(paymentSettings.message, tags);
    const success_message = translateTags(
      paymentSettings.success_message,
      tags,
    );
    const paymentOptions = (await IntegrationService.all(true, 'payment')).map(
      (e) => e.name,
    );

    return {
      success_message: success_message,
      has_tac: false,
      invoice: new Invoice(), // Typing needs it but we dont do anything with it.
      paid: false,
      pay_options: paymentOptions,
      message: message,
    };
  }
}
